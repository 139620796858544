.ant-card {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ant-card-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    > div {
      flex: 1 1 100%;
    }
  }
}